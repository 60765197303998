import DeleteIcon from '@mui/icons-material/Delete';
import { TableCell, TableRow, Link as UiLink } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';
import AdminPaginatedList from '../../components/AdminPaginatedList';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import AdminTabs from '../../components/AdminTabs';

const AssetList = () => {
  const { adminAssetApi } = useBlueprintAdmin();
  const [entities, setEntities] = useState<Array<any>>([]);

  const makeTabs = () => {
    const tabList: Array<any> = [];
    for (const entity of entities) {
      tabList.push({
        title: entity,
        template: (
          <AdminCard title={`All Assets In The ${entity} entity`}>
            <AdminPaginatedList
              columns={['id', 'key', 'userId', 'adminId']}
              url={`/admin/assets/list/${entity}`}
              itemTemplate={({ id, userId, adminId, key }, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <UiLink component={Link} to={`/assets/${id}`}>
                      {id}{' '}
                    </UiLink>
                  </TableCell>
                  <TableCell>{key}</TableCell>
                  <TableCell>{userId}</TableCell>
                  <TableCell>{adminId}</TableCell>
                </TableRow>
              )}
              filterKeys={[]}
            />
          </AdminCard>
        ),
      });
    }
    return tabList;
  };

  const tabs: Array<{
    title: string;
    template: React.ReactNode;
    icon?: React.ReactNode;
  }> = [
    {
      title: 'All',
      template: (
        <AdminCard title='All Assets'>
          <AdminPaginatedList
            columns={['id', 'key', 'userId', 'adminId', '']}
            url='/admin/assets'
            itemTemplate={({ id, userId, adminId, key }, index) => (
              <TableRow key={index}>
                <TableCell>
                  <UiLink component={Link} to={`/assets/${id}`}>
                    {id}{' '}
                  </UiLink>
                </TableCell>
                <TableCell>{key}</TableCell>
                <TableCell>{userId}</TableCell>
                <TableCell>{adminId}</TableCell>
                <TableCell>
                  <UiLink component={Link} to={`/assets/${id}/delete`}>
                    <DeleteIcon />
                  </UiLink>
                </TableCell>
              </TableRow>
            )}
            filterKeys={[]}
          />
        </AdminCard>
      ),
    },
    ...makeTabs(),
  ];

  const loadEntities = async () => {
    if (adminAssetApi) {
      try {
        const response = await adminAssetApi.getAllUsedEntities();
        if (response.success) {
          setEntities(response.data);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    loadEntities().catch((e) => {
      console.error(e);
    });
  }, []);

  return (
    <AdminPage animation={true} title='Assets' newAction='/assets/new'>
      <AdminTabs title={'Entities'} tabs={tabs} />
    </AdminPage>
  );
};

export default AssetList;
